.projects-container {
  display: flex;
  flex-direction: column;
  padding: 5% 10%;
  text-align: left;
}

.projects-container .projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

.projects-container .projects-grid .project {
  text-align: left;
}

.project h2:hover {
  text-decoration: underline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.zoom {
  border-radius: 5px;
  transition: transform 0.2s;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.05);
}

@media screen {
  @media (max-width: 700px) {
    .projects-container {
      display: block;
      padding-left: 5%;
      padding-right: 5%;
    }

    .projects-container .projects-grid {
      display: block;
    }

    .projects-container .projects-grid .project {
      padding-bottom: 70px;
    }

    .projects-container .projects-grid .project:last-child {
      padding-bottom: 0px;
    }
  }
}
